/* You can add global styles to this file, and also import other style files */
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
/* @import '~@angular/material/prebuilt-themes/deeppurple-amber.css'; */
@import url(./assets/css/style.css);
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

body{
    font-optical-sizing: auto;
    font-weight:400;
    font-style: normal;
}
.pt-0{
    padding-top: 0;
}
.border-none{
    border: none;
}
.none{
    display: none !important;
}
::placeholder ,.mat-mdc-select-placeholder{
    color: #637381;
    font-weight: 500;
    font-size: var(--font-size);
  }
  
  ::-ms-input-placeholder ,.mat-mdc-select-placeholder{ /* Edge 12 -18 */
    color: #637381;
    font-weight: 500;
    font-size: var(--font-size)
  }
.ng-touched.ng-invalid {
    border-color: red !important;
}
.error-message{
    color: red;
    font-size: 12px;
    margin: 3px 0 0;
    display: inline-block;
}
input:disabled, input:read-only{
    cursor: no-drop;
}
html, body { height: 100%; }
/* body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */
.custom-select-ui {
    /* background: #ccc; */
    display: block !important;
}
mat-form-field.mat-mdc-form-field.custom-select-ui.ng-tns-c1205077789-0.mat-mdc-form-field-type-mat-select.mat-form-field-appearance-fill.mat-form-field-hide-placeholder.mat-primary.ng-star-inserted {}

.custom-select-ui .mat-mdc-form-field-subscript-wrapper {
    display: none;
}
.custom-select-ui .mat-mdc-form-field-infix {
    min-height: 10px;
}
.custom-select-ui .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 20px;
}
.custom-select-ui .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    /* padding-top: 4px;
    padding-bottom: 8px; */
    padding-top: 0px;
    padding-bottom: 0px;
}
.custom-select-ui.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
    transform: translateY(-8px);
    top: 0px;
    position: relative;
}
.custom-select-ui .mdc-line-ripple::before , .custom-select-ui .mdc-line-ripple::after{
    border-bottom-width: 0 !important;
}
.custom-select-ui .mat-mdc-text-field-wrapper {
    width: 100%;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
}
.custom-select-ui .mdc-text-field--filled:not(.mdc-text-field--disabled){
    background-color: #fff;
}
.mdc-list-item , div.mat-mdc-select-panel.filter-option {
    overflow: visible !important;
}
.mat-mdc-option.mdc-list-item--disabled {
    cursor: default  !important;
    pointer-events: auto  !important;
}
.mat-mdc-option.mdc-list-item--disabled .mdc-list-item__primary-text{
    opacity: 1  !important;
}
.mdc-list-item.mdc-list-item--disabled, .mdc-list-item.mdc-list-item--non-interactive{
    cursor: pointer  !important;
}
.mat-mdc-select-min-line{
    font-size: var(--font-size);
}
.mdc-checkbox , .mdc-checkbox__native-control , .mat-mdc-checkbox-touch-target ,.mdc-checkbox__background{
    width: 15px !important;
    height: 15px !important;
}
.mat-mdc-checkbox{
 position: absolute;
 top: -5px;
}
.mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background{
    border-color: var(--background-primary-color) !important;
    background-color: var(--background-primary-color) !important;
}
.cdk-overlay-pane{
    /* width: auto !important; */
}
.mat-mdc-option .mdc-list-item__primary-text{
    font-size: var(--font-size) !important;
}
.mat-mdc-option{
    min-height: 35px !important;
}
.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic{
    stroke: var(--background-primary-color) !important;
}

.mat-custom-input .mat-mdc-text-field-wrapper {
    width: 100%;
    background: none !important;
    border: none !important;
    padding: 0 !important;
}
.mat-custom-input .mat-mdc-form-field-infix {
    flex: auto;
    min-width: 0;
    width: 180px;
    position: relative;
    box-sizing: border-box;
    padding: 0 0 0px !important;
    min-height: auto;
}
.mat-custom-input .mdc-text-field--filled .mdc-line-ripple::before , .mdc-text-field--filled .mdc-line-ripple::after{
border: none !important;
}
.mat-custom-input.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay{
    opacity: 0;
}
.mat-custom-input .mat-mdc-form-field-hint-wrapper, .mat-custom-input .mat-mdc-form-field-error-wrapper{
    padding: 0 !important;
}
.mat-custom-input.mat-mdc-form-field{
    display: block !important;
}


.success-snackbar .mdc-snackbar__surface {
    background: #00c955 !important;
    border-radius: 4px !important;
}
.error-snackbar .mdc-snackbar__surface {
    background: #e9292a !important;
    /* background: var(--background-primary-color) !important; */
    border-radius: 4px !important;
    /* padding: 8px 16px !important; */

}
.mat-mdc-snack-bar-container .mdc-snackbar__surface{
    height: 36px !important;
}
.success-snackbar .mdc-snackbar__surface .mat-mdc-snack-bar-label.mdc-snackbar__label {
    color: #fff !important;
    letter-spacing: .5px !important;
    font-size: var(--font-size) !important;
    text-shadow: none !important;
}
.back-arrow{
    position: relative;
    top: -2px;
}

::ng-deep .pagination .mat-mdc-paginator-range-label {
    margin: 0 10px 0 10px;
}
::ng-deep .pagination .mat-mdc-icon-button{
    width: 30px;
    height: 30px;
    line-height: 10px;
    padding: 0;
}
::ng-deep .pagination .mat-mdc-paginator-page-size{
    /* display: none; */
}
::ng-deep .mat-mdc-paginator-page-size-select{
    width: 54px !important;
}
::ng-deep .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix{
    padding: 0 !important;
    min-height: 0 !important;
}
::ng-deep .mat-mdc-paginator {
    background: none;
}
::ng-deep .loader .mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: #fff !important;
}
.p-datatable .p-datatable-thead > tr > th{
    background: #fafafa;
    border: 1px solid #f1f1f1;
    padding:10px !important;
    border-top: 0;
    font-weight: 600;
    white-space: nowrap;
}
.p-datatable .p-datatable-tbody > tr > td{
    border: 1px solid #f1f1f1;
    padding: 5px 10px !important;
    vertical-align: middle;
}
.p-inputtext{
    font-size: var(--font-size);
}
.p-paginator .p-paginator-pages .p-paginator-page{
    min-width: 2rem;
    height: 2rem;
    font-size: var(--font-size);
}
.p-paginator{
    justify-content: flex-end;
}
/* .required:after {
    content: " *";
    color: red;
  } */

  .p-datatable {
    height: calc(100vh - 265px) !important;
    overflow: auto !important;
    /* border-radius: 4px; */
    border-radius: 10px;
}
.p-datatable .p-datatable-thead > tr > th:last-child{
    border-top-right-radius: 10px;
}

.card{
    margin-bottom: 0;
    border-radius: 10px;
}
.card-shadow{
    box-shadow: 1px 1px 6px #d2d2d2;
}
.card .card-body {
    padding-bottom: 15px;
}


/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(231, 231, 231); 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(219, 219, 219); 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #acacac; 
  }
  
  .active-icon svg , .active-icon svg g, .active-icon svg g path, .active-icon svg g circle{ 
    fill: var(--background-primary-color) !important;
    stroke: var(--background-primary-color) !important;
  }
.sidebar .sidebar-menu .activeMenu .icons svg , .sidebar .sidebar-menu .activeMenu .icons svg g, .sidebar .sidebar-menu .activeMenu .icons svg g path, .sidebar .sidebar-menu .activeMenu .icons svg g circle{ 
    fill: var(--background-primary-color) !important;
    stroke: var(--background-primary-color) !important;
  }
  .pagination{
    position: relative;
  }
  .pagination .refreshIcon{
      width: 18px;
      cursor: pointer;
      position: absolute;
      right: 100px;
      /* transform: translateX(-100px); */
    }
    .pagination .refreshIcon.spin{
        animation: spin 3s;
    }
  @keyframes spin {
    50% {transform: rotate(360deg);}
  }