
.timeline {
    position: relative;
    list-style: none;
    max-width: 1200px;
    float: left;
    margin: 0 auto;
    padding: 20px 0;
  }
  .timeline:before {
    content: " ";
    width: 3px;
    margin-left: -1.5px;
    background-color: #E9ECEF;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
  }
  
  .timeline li {
    position: relative;
    margin-bottom: 20px;
    float: left;
    width: 100%;
  }
  .timeline li:before, .timeline li:after {
    content: " ";
    display: table;
  }
  .timeline li .timeline-badge {
    z-index: 1;
    width: 50px;
    height: 50px;
    margin-left: -25px;
    text-align: center;
    font-size: 1.4em;
    line-height: 50px;
    color: #fff;
    overflow: hidden;
    border-radius: 50%;
    position: absolute;
    top: 16px;
    left: 50%;
  }
  .timeline li .timeline-badge.success {
    background-color: #26af48;
  }
  .timeline li .timeline-badge.warning {
    background-color: #FF9900;
  }
  .timeline li .timeline-badge.danger {
    background-color: #f62d51;
  }
  .timeline li .timeline-badge.info {
    background-color: #0d6efd;
  }
  .timeline li .timeline-panel {
    float: left;
    position: relative;
    width: 46%;
    padding: 20px;
    border: 1px solid #E9ECEF;
    border-radius: 0.25rem;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
  }
  .timeline li.timeline-inverted .timeline-panel {
    float: right;
  }
  
  /*-----------------
   Timeline
  -----------------------*/
  .timeline {
    position: relative;
    list-style: none;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 0 20px;
  }
  
  .timeline:before {
    content: " ";
    position: absolute;
    width: 3px;
    margin-left: -1.5px;
    background-color: #E9ECEF;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
  }
  
  .timeline > li {
    position: relative;
    margin-bottom: 20px;
  }
  
  .timeline > li:before,
  .timeline > li:after {
    content: " ";
    display: table;
  }
  
  .timeline > li:after {
    clear: both;
  }
  
  .timeline > li:before,
  .timeline > li:after {
    content: " ";
    display: table;
  }
  
  .timeline > li:after {
    clear: both;
  }
  
  .timeline > li > .timeline-panel {
    float: left;
    position: relative;
    width: 46%;
    padding: 20px;
    border: 1px solid #E9ECEF;
    border-radius: 0.25rem;
    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
  }
  
  .timeline > li > .timeline-panel:before {
    content: " ";
    display: inline-block;
    position: absolute;
    border-top: 8px solid transparent;
    border-right: 0 solid #E9ECEF;
    border-bottom: 8px solid transparent;
    border-left: 8px solid #E9ECEF;
    position: absolute;
    top: 26px;
    right: -8px;
  }
  
  .timeline > li > .timeline-panel:after {
    content: " ";
    display: inline-block;
    position: absolute;
    top: 27px;
    right: -7px;
    border-top: 7px solid transparent;
    border-right: 0 solid #fff;
    border-bottom: 7px solid transparent;
    border-left: 7px solid #fff;
  }
  
  .timeline > li > .timeline-badge {
    z-index: 1;
    width: 50px;
    height: 50px;
    margin-left: -25px;
    text-align: center;
    font-size: 1.4em;
    line-height: 50px;
    color: #fff;
    overflow: hidden;
    border-radius: 50%;
    position: absolute;
    top: 16px;
    left: 50%;
  }
  
  .timeline > li.timeline-inverted > .timeline-panel {
    float: right;
  }
  
  .timeline > li.timeline-inverted > .timeline-panel:before {
    right: auto;
    left: -8px;
    border-right-width: 8px;
    border-left-width: 0;
  }
  
  .timeline > li.timeline-inverted > .timeline-panel:after {
    right: auto;
    left: -7px;
    border-right-width: 7px;
    border-left-width: 0;
  }
  
  .timeline-badge.primary {
    background-color: var(--background-primary-color);
  }
  
  .timeline-badge.success {
    background-color: #26af48;
  }
  
  .timeline-badge.warning {
    background-color: #FF9900;
  }
  
  .timeline-badge.danger {
    background-color: #f62d51;
  }
  
  .timeline-badge.info {
    background-color: #0d6efd;
  }
  
  .timeline-title {
    margin-top: 0;
    color: inherit;
    font-weight: 400;
  }
  
  .timeline-body > p,
  .timeline-body > ul {
    margin-bottom: 0;
  }
  
  .timeline-body > p + p {
    margin-top: 5px;
  }
  
  .cd-horizontal-timeline .events a {
    padding-bottom: 6px;
    color: var(--background-primary-color);
  }
  
  .cd-horizontal-timeline .filling-line,
  .cd-horizontal-timeline .events a.selected::after {
    background: var(--background-primary-color);
  }
  
  .cd-horizontal-timeline .events a.selected::after {
    border-color: var(--background-primary-color);
  }
  
  .cd-horizontal-timeline .m-t-40 {
    margin-top: 25px !important;
  }
  
  .cd-horizontal-timeline ol,
  .cd-horizontal-timeline ul {
    list-style: none;
  }
  
  .cd-timeline-navigation a:hover,
  .cd-timeline-navigation a:focus {
    border-color: var(--background-primary-color);
  }
  
  .cd-horizontal-timeline a,
  .cd-horizontal-timeline a:hover,
  .cd-horizontal-timeline a:focus {
    color: var(--background-primary-color);
  }
  
  .cd-horizontal-timeline blockquote,
  .cd-horizontal-timeline q {
    quotes: none;
  }
  
  .cd-horizontal-timeline blockquote:before,
  .cd-horizontal-timeline blockquote:after,
  .cd-horizontal-timeline q:before,
  .cd-horizontal-timeline q:after {
    content: "";
    content: none;
  }
  
  .cd-horizontal-timeline table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  .cd-horizontal-timeline {
    opacity: 0;
    margin: 2em auto;
    -webkit-transition: opacity 0.2s;
    -moz-transition: opacity 0.2s;
    transition: opacity 0.2s;
  }
  
  .cd-horizontal-timeline::before {
    content: "mobile";
    display: none;
  }
  
  .cd-horizontal-timeline.loaded {
    opacity: 1;
  }
  
  .cd-horizontal-timeline .timeline {
    position: relative;
    height: 100px;
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .cd-horizontal-timeline .events-wrapper {
    position: relative;
    height: 100%;
    overflow: hidden;
    margin: 0 40px;
  }
  
  .cd-horizontal-timeline .events-wrapper::after,
  .cd-horizontal-timeline .events-wrapper::before {
    content: "";
    z-index: 2;
    height: 100%;
    width: 20px;
    position: absolute;
    top: 0;
  }
  
  .cd-horizontal-timeline .events-wrapper::before {
    left: 0;
  }
  
  .cd-horizontal-timeline .events-wrapper::after {
    right: 0;
  }
  
  .cd-horizontal-timeline .events {
    z-index: 1;
    height: 2px;
    background: #dfdfdf;
    -webkit-transition: all 0.6s;
    -ms-transition: all 0.6s;
    transition: all 0.6s;
    position: absolute;
    top: 30px;
    left: 0;
  }
  
  .cd-horizontal-timeline .filling-line {
    z-index: 1;
    height: 100%;
    width: 100%;
    background-color: var(--background-primary-color);
    transform: scaleX(0);
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform-origin: left center;
    -webkit-transform-origin: left center;
    -ms-transform-origin: left center;
    -webkit-transition: all 0.6s;
    -ms-transition: all 0.6s;
    transition: all 0.6s;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .cd-horizontal-timeline .events a {
    z-index: 2;
    text-align: center;
    font-size: 16px;
    padding-bottom: 15px;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    position: absolute;
    bottom: 0;
  }
  
  .cd-horizontal-timeline .events a::after {
    content: "";
    height: 12px;
    width: 12px;
    border: 2px solid #dfdfdf;
    background-color: #F8F8F8;
    transition: background-color 0.3s, border-color 0.3s;
    border-radius: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    position: absolute;
    right: auto;
    bottom: -5px;
    left: 50%;
  }
  
  .no-touch .cd-horizontal-timeline .events a:hover::after {
    background-color: var(--background-primary-color);
    border-color: var(--background-primary-color);
  }
  
  .cd-horizontal-timeline .events a.selected {
    pointer-events: none;
  }
  
  .cd-horizontal-timeline .events a.selected::after {
    background-color: var(--background-primary-color);
    border-color: var(--background-primary-color);
  }
  
  .cd-horizontal-timeline .events a.older-event::after {
    border-color: var(--background-primary-color);
  }
  
  .cd-timeline-navigation a {
    z-index: 1;
    height: 34px;
    width: 34px;
    border: 2px solid #dfdfdf;
    overflow: hidden;
    color: transparent;
    text-indent: 100%;
    white-space: nowrap;
    transition: border-color 0.3s;
    border-radius: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    position: absolute;
    top: 50%;
    bottom: auto;
  }
  
  .cd-timeline-navigation a::after {
    content: "";
    height: 16px;
    width: 16px;
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    background: url(https://dreamspos.dreamstechnologies.com/html/template/assets/img/arrow.svg) no-repeat 0 0;
  }
  
  .cd-timeline-navigation a.prev {
    transform: translateY(-50%) rotate(180deg);
    -webkit-transform: translateY(-50%) rotate(180deg);
    -ms-transform: translateY(-50%) rotate(180deg);
  }
  
  .cd-timeline-navigation a.next {
    right: 0;
  }
  
  .no-touch .cd-timeline-navigation a:hover {
    border-color: #7b9d6f;
  }
  
  .cd-timeline-navigation a.inactive {
    cursor: not-allowed;
  }
  
  .cd-timeline-navigation a.inactive::after {
    background-position: 0 -16px;
  }
  
  .no-touch .cd-timeline-navigation a.inactive:hover {
    border-color: #dfdfdf;
  }
  
  .cd-horizontal-timeline .events-content {
    position: relative;
    width: 100%;
    margin: 2em 0;
    overflow: hidden;
    transition: height 0.4s;
  }
  
  .cd-horizontal-timeline .events-content li {
    z-index: 1;
    width: 100%;
    padding: 0 5%;
    opacity: 0;
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
  }
  
  .cd-horizontal-timeline .events-content li.selected {
    position: relative;
    opacity: 1;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
  }
  
  .cd-horizontal-timeline .events-content li.enter-right,
  .cd-horizontal-timeline .events-content li.leave-right {
    -webkit-animation-name: cd-enter-right;
    -moz-animation-name: cd-enter-right;
    animation-name: cd-enter-right;
  }
  
  .cd-horizontal-timeline .events-content li.enter-left,
  .cd-horizontal-timeline .events-content li.leave-left {
    -webkit-animation-name: cd-enter-left;
    -moz-animation-name: cd-enter-left;
    animation-name: cd-enter-left;
  }
  
  .cd-horizontal-timeline .events-content li.leave-right,
  .cd-horizontal-timeline .events-content li.leave-left {
    -webkit-animation-direction: reverse;
    -moz-animation-direction: reverse;
    animation-direction: reverse;
  }
  
  .cd-horizontal-timeline .events-content li > * {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .cd-horizontal-timeline .events-content h2 {
    font-weight: 600;
    margin-bottom: 0px;
  }
  
  .cd-horizontal-timeline .events-content em {
    display: block;
    font-style: italic;
    margin: 10px auto;
  }
  
  .cd-horizontal-timeline .events-content em::before {
    content: "- ";
  }
  
  .cd-horizontal-timeline .events-content p {
    font-size: 16px;
  }
  
  .cd-horizontal-timeline .timeline:before {
    content: " ";
    display: none;
    bottom: 0;
    left: 0%;
    width: 0px;
    margin-left: -1.5px;
    background-color: #eeeeee;
  }
  
  .cd-horizontal-timeline .events-content em,
  .cd-horizontal-timeline .events-content p {
    line-height: 30px;
  }
  