
.sidebar.new-header {
    position: fixed;
    width: 100%;
    height: 68px;
    background: #fff;
    border-top: 1px solid #D9D9D9;
    box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
    z-index: 9;
  }
  .sidebar.new-header .sidebar-menu {
    padding: 0 15px;
  }
  .sidebar.new-header .sidebar-menu > ul > li {
    margin-bottom: 0;
    margin-right: 20px;
  }
  .sidebar.new-header .sidebar-menu > ul > li > a {
    padding: 22px 15px;
  }
  .sidebar.new-header .sidebar-menu > ul > li > a:hover {
    background: transparent;
  }
  .sidebar.new-header .sidebar-menu > ul > li > a:hover span {
    color: var(--background-primary-color);
  }
  .sidebar.new-header .sidebar-menu > ul > li > a:hover img, .sidebar.new-header .sidebar-menu > ul > li > a:hover svg {
    filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%) hue-rotate(326deg) brightness(105%) contrast(112%);
  }
  .sidebar.new-header .sidebar-menu > ul > li > a img {
    top: 0 !important;
    width: auto;
  }
  .sidebar.new-header .sidebar-menu > ul > li a {
    font-weight: 500;
    font-size: 15px;
    color: #637381;
  }
  .sidebar.new-header .sidebar-menu > ul > li a img {
    position: relative;
    top: 3px;
    width: 18px;
    margin-right: 7px;
  }
  .sidebar.new-header .sidebar-menu > ul > li a svg {
    width: 18px;
    margin-right: 7px;
  }
  .sidebar.new-header .sidebar-menu > ul > li .menu-arrow {
    position: initial;
    transform: rotate(90deg);
    margin-left: 15px;
  }
  .sidebar.new-header .sidebar-menu > ul > li ul {
    position: absolute;
    width: 250px;
    height: auto;
  }
  .sidebar.new-header .sidebar-menu > ul > li ul li.submenu a {
    padding: 8px 15px !important;
  }
  .sidebar.new-header .sidebar-menu > ul > li ul li.submenu a .menu-arrow {
    float: right;
    margin-right: 15px;
  }
  .sidebar.new-header .sidebar-menu > ul > li ul li.submenu a i {
    margin-right: 7px;
  }
  .sidebar.new-header .sidebar-menu > ul > li ul li.submenu a img {
    position: relative;
    top: 3px;
    width: 18px;
    margin-right: 7px;
  }
  .sidebar.new-header .sidebar-menu > ul > li ul li.submenu a svg {
    width: 18px;
    margin-right: 7px;
  }
  .sidebar.new-header .sidebar-menu > ul > li ul li.submenu ul {
    box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
  }
  .sidebar.new-header .sidebar-menu > ul > li ul li.submenu ul {
    position: relative;
    padding: 0;
  }
  .sidebar.new-header .sidebar-menu > ul > li ul li.submenu ul li a {
    padding: 8px 10px 8px 40px !important;
  }
  .sidebar.new-header .sidebar-menu > ul > li ul li a {
    padding: 8px 15px !important;
  }
  .sidebar.new-header .sidebar-menu > ul > li ul li a::after {
    content: none !important;
  }
  .sidebar.sidebar-two {
    left: auto;
    top: 90px;
    border: 0 !important;
  }
  .sidebar.sidebar-two .sidebar-menu > ul > li.submenu ul li a {
    display: flex !important;
  }
  .sidebar.sidebar-two .sidebar-menu > ul > li.submenu ul li a img {
    width: 18px;
  }
  .sidebar.sidebar-two .sidebar-menu > ul > li.submenu ul li a span {
    margin-left: 10px;
  }
  .sidebar.sidebar-two .sidebar-menu > ul > li.submenu ul li a::after {
    content: none;
  }
  .sidebar.sidebar-two .sidebar-menu > ul > li.submenu ul li a .menu-arrow {
    top: 10px;
  }
  .sidebar.sidebar-two .sidebar-menu > ul > li.submenu ul li ul li a {
    padding: 8px 8px 8px 64px;
  }
  .sidebar.sidebar-two .sidebar-menu .menu-arrow {
    position: absolute;
    top: 12px;
    right: 15px;
  }
  .sidebar.side-three.new-header {
    position: relative;
    top: 0;
    width: 100%;
    height: 150px;
    background: #1B2950;
    border-top: 1px solid #D9D9D9;
    box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
    z-index: unset;
  }
  .sidebar.side-three.new-header .sidebar-menu {
    padding: 0 15px;
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li {
    margin-bottom: 0;
    margin-right: 20px;
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li > a {
    padding: 22px 15px;
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li > a img, .sidebar.side-three.new-header .sidebar-menu > ul > li > a svg {
    filter: brightness(0) invert(1);
    margin-right: 0;
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li > a:hover {
    background: transparent;
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li > a:hover span {
    color: var(--background-primary-color) !important;
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li > a:hover img, .sidebar.side-three.new-header .sidebar-menu > ul > li > a:hover svg {
    filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%) hue-rotate(326deg) brightness(105%) contrast(112%);
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li > a img {
    top: 0 !important;
    width: auto;
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li > a::before {
    content: "";
    position: absolute;
    top: 50%;
    right: -10px;
    width: 1px;
    height: 15px;
    background: #6B7A99;
    transform: translateY(-50%);
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li > a span {
    color: #fff !important;
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li a {
    font-weight: 500;
    font-size: 15px;
    color: #fff;
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li a img {
    position: relative;
    top: 3px;
    width: 18px;
    margin-right: 7px;
    filter: brightness(0) invert(1);
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li a svg {
    width: 18px;
    margin-right: 7px;
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li a:hover img, .sidebar.side-three.new-header .sidebar-menu > ul > li a:hover svg {
    filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%) hue-rotate(326deg) brightness(105%) contrast(112%);
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li .menu-arrow {
    position: initial;
    transform: rotate(90deg);
    margin-left: 15px;
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li ul {
    position: absolute;
    width: 250px;
    height: auto;
    z-index: 1;
    background: #1B2950;
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu a {
    padding: 8px 15px !important;
    color: #fff;
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu a .menu-arrow {
    float: right;
    margin-right: 15px;
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu a i {
    margin-right: 7px;
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu a img {
    position: relative;
    top: 3px;
    width: 18px;
    margin-right: 7px;
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu a svg {
    width: 18px;
    margin-right: 7px;
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu a:hover {
    color: var(--background-primary-color);
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu a:hover img {
    filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%) hue-rotate(326deg) brightness(105%) contrast(112%);
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu ul {
    box-shadow: none;
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu ul {
    position: relative;
    padding: 0;
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li ul li.submenu ul li a {
    padding: 8px 10px 8px 40px !important;
    color: #fff;
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li ul li a {
    padding: 8px 15px !important;
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li ul li a::after {
    content: none !important;
  }
  .sidebar.side-three.new-header .sidebar-menu > ul > li:last-child > a::before {
    content: none;
  }
  
  .sidebar.sidebar-four {
    border: 0;
    box-shadow: 0px 4px 14px rgba(198, 198, 198, 0.25);
  }
  .sidebar.sidebar-four .sidebar-menu {
    border-radius: 0 20px 20px 0;
  }
  .sidebar.sidebar-four .sidebar-menu .ui-aside {
    float: left;
    width: 100%;
    max-width: 66px;
    margin-left: 0px;
    color: rgba(255, 255, 255, 0.5);
    -webkit-transition: all 0.299s;
    -ms-transition: all 0.299s;
    transition: all 0.299s;
    margin: 0;
    padding: 0;
    box-shadow: 0px 0 14px rgba(198, 198, 198, 0.25);
    min-height: 100vh;
  }
  .sidebar.sidebar-four .sidebar-menu .ui-aside .nav-tabs {
    padding: 0 19px;
    border: 0;
  }
  .sidebar.sidebar-four .sidebar-menu .ui-aside .nav-tabs .nav-item .nav-link {
    padding: 20px 5px;
    border-bottom: 1px solid #D9D9D9;
    border-width: 0 0 1px;
  }
  .sidebar.sidebar-four .sidebar-menu .ui-aside .nav-tabs .nav-item .nav-link:hover {
    border-width: 0 0 1px;
    border-bottom: 1px solid #D9D9D9;
  }
  .sidebar.sidebar-four .sidebar-menu .ui-aside .nav-tabs .nav-item .nav-link:active, .sidebar.sidebar-four .sidebar-menu .ui-aside .nav-tabs .nav-item .nav-link.active {
    border-color: #fff #fff #D9D9D9;
  }
  .sidebar.sidebar-four .sidebar-menu .ui-aside .nav-tabs .nav-item .nav-link:active img, .sidebar.sidebar-four .sidebar-menu .ui-aside .nav-tabs .nav-item .nav-link.active img {
    filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%) hue-rotate(326deg) brightness(105%) contrast(112%);
  }
  .sidebar.sidebar-four .sidebar-menu .ui-aside .nav-tabs .nav-item .nav-link:active::after, .sidebar.sidebar-four .sidebar-menu .ui-aside .nav-tabs .nav-item .nav-link.active::after {
    content: none;
  }
  .sidebar.sidebar-four .sidebar-menu .ui-aside .nav-tabs .nav-item .nav-link::before {
    content: none;
  }
  .sidebar.sidebar-four .sidebar-menu .ui-aside .nav-tabs .nav-item:last-child .nav-link {
    border-bottom: 0;
  }
  .sidebar.sidebar-four .tab-content-four {
    padding-top: 20px;
    margin-left: 10px;
    width: 100%;
  }
  .sidebar.sidebar-four ul ul {
    display: none;
  }
  .sidebar.sidebar-four .sidebar-menu {
    padding: 0 20px 0 0;
  }
  .sidebar.sidebar-four .sidebar-menu ul li a {
    padding: 10px 15px 10px 25px;
    position: relative;
    color: #637381;
  }
  .sidebar.sidebar-four .sidebar-menu ul li a svg {
    width: 18px;
    color: #637381;
  }
  .sidebar.sidebar-four .sidebar-menu ul li a img {
    width: 18px;
    color: #637381;
  }
  .sidebar.sidebar-four .sidebar-menu ul li a:hover {
    color: var(--background-primary-color);
  }
  .sidebar.sidebar-four .sidebar-menu ul li a:hover img {
    filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%) hue-rotate(326deg) brightness(105%) contrast(112%);
  }
  .sidebar.sidebar-four .sidebar-menu ul li a:hover span {
    color: var(--background-primary-color);
  }
  .sidebar.sidebar-four .sidebar-menu ul li a:hover svg {
    color: #fff;
  }
  .sidebar.sidebar-four .sidebar-menu ul li a::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    background: #C8C8C8;
    width: 5px;
    height: 5px;
  }
  .sidebar.sidebar-four .sidebar-menu ul li a.active {
    color: var(--background-primary-color);
  }
  .sidebar.sidebar-four .sidebar-menu ul li a.active svg {
    color: #fff;
  }
  .sidebar.sidebar-four .sidebar-menu ul li a.active img {
    filter: brightness(0) saturate(100%) invert(75%) sepia(10%) saturate(5676%) hue-rotate(326deg) brightness(105%) contrast(112%);
  }
  .sidebar.sidebar-four .sidebar-menu ul li a.active span {
    color: #fff;
  }
  .sidebar.sidebar-four .sidebar-menu ul li a span {
    margin-left: 0;
    font-size: 15px;
    font-weight: 500;
    color: #637381;
  }
  .sidebar.sidebar-four .sidebar-menu ul li a .menu-arrow {
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    text-rendering: auto;
    line-height: 40px;
    font-size: 18px;
    line-height: 18px;
    position: absolute;
    top: 12px;
    right: 15px;
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -webkit-transition: all 0.6s;
    -ms-transition: all 0.6s;
    transition: all 0.6s;
  }
  .sidebar.sidebar-four .sidebar-menu ul li a.subdrop .menu-arrow {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
  }
  .sidebar.sidebar-four .sidebar-menu ul li ul li a {
    padding: 10px 15px 10px 25px;
    left: 14px;
  }
  .sidebar {
    background-color: transparent;
    border-right: 1px solid transparent;
    margin-top: 0;
    z-index: 99;
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 0;
    -webkit-transition: all 0.6s;
    -ms-transition: all 0.6s;
    transition: all 0.6s;
    border-right: 1px solid #E8EBED;
    width: 220px;
    overflow: auto;
    background: #fff;
  }
  .sidebar .slimScrollDiv {
    width: 260px !important;
    overflow: visible !important;
    background: #fff;
  }
  .sidebar .sidebar-menu {
    padding: 15px;
    padding-right: 0;
    padding-bottom: 30px;
  }
  .sidebar .sidebar-menu > ul > li {
    margin-bottom: 10px;
    cursor: pointer;
  }
  .sidebar .sidebar-menu > ul > li.active a {
    color: #fff;
  }
  .sidebar .sidebar-menu > ul > li.active svg {
    color: #FE9F43;
  }
  .sidebar .sidebar-menu > ul > li svg {
    width: 18px;
    margin-right: 10px;
  }
  .sidebar .sidebar-menu > ul > li > a {
    padding: 10px 15px;
    position: relative;
    color: #212B36;
  }
  .sidebar .sidebar-menu > ul > li > a svg {
    color: #637381;
  }
  .sidebar .sidebar-menu > ul > li > a img {
    width: 18px;
    color: #637381;
  }
  .sidebar .sidebar-menu > ul > li > a:hover {
    background: rgba(254, 159, 67, 0.08);
    color: #FE9F43;
    border-radius: 5px;
  }
  .sidebar .sidebar-menu > ul > li > a:hover img {
    filter: invert(72%) sepia(76%) saturate(1430%) hue-rotate(327deg) brightness(103%) contrast(101%);
  }
  .sidebar .sidebar-menu > ul > li > a:hover span {
    color: #FE9F43;
  }
  .sidebar .sidebar-menu > ul > li > a:hover svg {
    color: #FE9F43;
  }
  .sidebar .sidebar-menu > ul > li > a.active {
    background: rgba(254, 159, 67, 0.08);
    color: #FE9F43;
    border-radius: 5px;
  }
  .sidebar .sidebar-menu > ul > li > a.active svg {
    color: #fff;
  }
  .sidebar .sidebar-menu > ul > li > a.active img {
    filter: invert(72%) sepia(76%) saturate(1430%) hue-rotate(327deg) brightness(103%) contrast(101%);
  }
  .sidebar .sidebar-menu > ul > li > a.active span {
    color: #FE9F43;
  }
  .sidebar .sidebar-menu > ul > li > a.active .menu-arrow {
    background: #FFEDDC;
  }
  .sidebar .sidebar-menu > ul > li > a.active .menu-arrow::before {
    border-color: #FE9F43;
  }
  .sidebar .sidebar-menu > ul > li > a span {
    font-size: 15px;
    font-weight: 500;
    color: #637381;
  }
  .sidebar .sidebar-menu > ul > li.active a {
    background: rgba(254, 159, 67, 0.08);
    border-radius: 5px;
  }
  .sidebar .sidebar-menu > ul > li.active a img {
    filter: invert(72%) sepia(76%) saturate(1430%) hue-rotate(327deg) brightness(103%) contrast(101%);
  }
  .sidebar .sidebar-menu > ul > li.active a span {
    color: #FE9F43;
  }
  .sidebar .sidebar-menu > ul > li.submenu ul {
    border-bottom: 1px solid rgba(222, 226, 230, 0.5);
    padding: 10px 0;
    display: none;
  }
  .sidebar .sidebar-menu > ul > li.submenu ul li a {
    font-weight: 500;
    font-size: var(--font-size);
    color: #212B36;
    position: relative;
    display: block;
    padding: 8px 8px 8px 40px;
  }
  .sidebar .sidebar-menu > ul > li.submenu ul li a.active {
    color: #092C4C;
  }
  .sidebar .sidebar-menu > ul > li.submenu ul li a.active:after {
    background: #FE9F43;
    border: 2px solid #FDB;
  }
  .sidebar .sidebar-menu > ul > li.submenu ul li a::after {
    content: "";
    background: rgba(50, 71, 92, 0.38);
    width: 8px;
    height: 8px;
    border: 2px solid #fff;
    border-radius: 50px;
    position: absolute;
    top: 16px;
    left: 16px;
  }
  .sidebar-inner.slimscroll {
    width: 100%;
  }
  .mini-sidebar .sidebar-inner.slimscroll {
    width: auto;
  }
  .sidebar .sidebar-menu > ul > li.submenu ul li a:hover {
    color: var(--background-primary-color);
  }
  .sidebar .sidebar-menu > ul > li.submenu ul li a:hover:after {
    background: #FE9F43;
    border: 2px solid #FDB;
  }
  .sidebar .sidebar-menu > ul > li.submenu-open ul {
    border-bottom: 1px solid rgba(222, 226, 230, 0.5);
    padding: 10px 0;
    display: block;
  }
  .sidebar .sidebar-menu > ul > li.submenu-open ul li a {
    font-weight: 400;
    font-size: var(--font-size);
    color: #313131;
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 15px;
  }
  .sidebar .sidebar-menu > ul > li.submenu-open ul li a.active {
    color: var(--background-primary-color);
    background: var(--background-primary-hover-color);
    border-radius: 5px;
    transition: 0.5s;   
  }
  .sidebar .sidebar-menu .activeMenu h6{
    color: var(--background-primary-color) !important;
    transition: 0.3s;   
  }
  .sidebar .sidebar-menu > ul > li.submenu-open ul li a.active:after {
    background: #FE9F43;
    border: 2px solid #FDB;
  }
  .sidebar .sidebar-menu > ul > li.submenu-open ul li a:hover {
    color: var(--background-primary-color);
    background: var(--background-primary-hover-color);
    border-radius: 5px;
    transition: 0.5s;  
  }
  .sidebar .sidebar-menu > ul > li.submenu-open ul li a:hover:after {
    background: #FE9F43;
    border: 2px solid #FDB;
  }
  .sidebar .sidebar-menu > ul > li.submenu-open ul > li.active a {
    background: rgba(254, 159, 67, 0.08);
    border-radius: 5px;
    color: #FE9F43;
  }
  .sidebar .sidebar-menu > ul > li.submenu-open .submenu-hdr {
    font-weight: 500;
    font-size: var(--font-size);
    color: #1B2950;
    margin: 15px 0 0;
  }
  .sidebar .sidebar-menu > ul > li.submenu-open:first-child h6 {
    margin-top: 0;
  }
  .sidebar .sidebar-menu > ul > li.submenu-open .submenu ul {
    display: none;
    border: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  .sidebar .sidebar-menu > ul li.active a {
    color: #FE9F43;
  }
  .sidebar .sidebar-menu > ul li.active svg {
    color: #FE9F43;
  }
  .sidebar .sidebar-menu > ul li .submenu > a.active {
    background: rgba(254, 159, 67, 0.08);
    color: #FE9F43;
    border-radius: 5px;
  }
  .sidebar .sidebar-menu > ul li .submenu > a.active span {
    color: #FE9F43;
  }
  .sidebar .sidebar-menu > ul li .submenu > a.active .menu-arrow {
    background: #FFEDDC;
  }
  .sidebar .sidebar-menu > ul li .submenu > a.active .menu-arrow::before {
    border-color: #FE9F43;
  }
  .sidebar .sidebar-menu .submenu-open .submenu ul li a {
    margin-left: 25px;
  }
  .sidebar .sidebar-menu .submenu-open .submenu ul li a::after {
    content: "";
    background: rgba(50, 71, 92, 0.38);
    width: 8px;
    height: 8px;
    border: 2px solid #fff;
    border-radius: 50px;
    position: absolute;
    top: 16px;
    left: 0;
  }
  .sidebar .sidebar-menu .submenu-open .submenu ul li a:hover {
    color: var(--background-primary-color);
  }
  .sidebar .sidebar-menu .submenu-open .submenu ul li a:hover:after {
    background: #FE9F43;
    border: 2px solid #FDB;
  }
  .sidebar .sidebar-menu .menu-arrow {
    display: flex;
    text-rendering: auto;
    line-height: 40px;
    font-size: 18px;
    line-height: 18px;
    background: rgba(27, 41, 80, 0.04);
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    top: 12px;
    right: 15px;
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -webkit-transition: all 0.6s;
    -ms-transition: all 0.6s;
    transition: all 0.6s;
  }
  .sidebar .sidebar-menu .menu-arrow::before {
    height: 5px;
    width: 5px;
    border-top: 0;
    content: "";
    border-left: 0;
    border-right: 1px solid #67748E;
    border-bottom: 1px solid #67748E;
    transform: rotate(-45deg);
    position: absolute;
    left: 5px;
    top: 5px;
  }
  .sidebar .sidebar-menu.subdrop .menu-arrow {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    background: #FFEDDC;
  }
  .sidebar .nav-link {
    padding: 10px;
    color: #212B36;
    position: relative;
  }
  .sidebar .nav-link.active {
    color: var(--background-primary-color);
    background: transparent;
    position: relative;
  }
  .sidebar .nav-link.active:after {
    content: "";
    border-width: 10px;
    border-color: transparent var(--background-primary-color) transparent transparent;
    border-style: solid;
    position: absolute;
    top: 36px;
    right: 0;
  }
  .sidebar .sidebar-left {
    width: 100px;
    -webkit-box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.05);
    box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.05);
  }
  .sidebar .sidebar-right {
    width: 250px;
    height: 100%;
    -webkit-transition: all 0.6s;
    -ms-transition: all 0.6s;
    transition: all 0.6s;
    display: none;
  }
  .sidebar .sidebar-right .slimScrollDiv {
    width: 250px !important;
    overflow: visible !important;
  }
  .sidebar .sidebar-right .tab-content {
    padding: 20px 20px 40px;
  }
  .sidebar .sidebar-right p {
    font-size: 10px;
    color: #9e9e9e;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 10px;
  }
  .sidebar .sidebar-right ul li.active a {
    background: linear-gradient(46.62deg, var(--background-primary-color) 0%, #423B93 93.64%);
    color: #fff;
  }
  .sidebar .sidebar-right ul li a {
    font-weight: 400;
    font-size: var(--font-size);
    color: #212B36;
  }
  .sidebar .sidebar-right ul li a.active {
    color: var(--background-primary-color);
  }
  .sidebar .sidebar-right .menu-arrow {
    -webkit-transition: -webkit-transform 0.15s;
    -o-transition: -o-transform 0.15s;
    transition: transform 0.15s;
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    text-rendering: auto;
    line-height: 40px;
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 18px;
    top: 0px;
    position: relative;
    font-weight: 700;
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
  }
  .sidebar .sidebar-right .menu-arrow:before {
    content: "\f105";
  }
  .sidebar li a.subdrop .menu-arrow {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
  }
  .sidebar .nav-items .nav-link {
    border-radius: 0;
    border-bottom: 1px solid #D8D8D8;
    padding: 20px 0;
    -webkit-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  .sidebar .nav-items .nav-link:hover {
    box-shadow: 0 0 0 150px #423B93 inset;
    color: #fff;
  }
  .sidebar .nav-items .nav-link span {
    margin-top: 15px;
    font-weight: 500;
  }
  .sidebar .sidemenu a {
    width: 100%;
    font-weight: 500;
    display: block;
    border-radius: 5px;
    margin: 0 0 10px;
    padding: 10px;
  }
  .sidebar .sidemenu a:hover {
    background: linear-gradient(46.62deg, var(--background-primary-color) 0%, #423B93 93.64%);
    color: #fff;
  }
  .sidebar .sidemenu a:hover svg {
    color: #fff;
  }
  .sidebar .sidemenu a svg {
    stroke-width: 1px;
  }
  .sidebarrightmenu .sidebar-right {
    display: block;
  }
  
  .slide-nav .sidebar {
    margin-left: 0;
  }
  
  .sidebar-overlay {
    display: none;
    height: 100%;
    width: 100%;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 60px;
    left: 0;
  }
  .sidebar-overlay.opened {
    display: block;
  }
  
  .nosidebar .sidebar {
    left: -350px;
  }
  .nosidebar .page-wrapper {
    margin: 0;
  }
  .nosidebar #toggle_btn svg {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
  }
  
  .mini-sidebar .active.subdrop ~ ul {
    display: none !important;
  }
  
  .mini-sidebar.expand-menu .active.subdrop ~ ul {
    display: block !important;
  }
  .sidebar.new-header.sidebar-one .submenu ul {
    background: #FAFBFE;
  }

  .sidebar-settings {
    width: 450px;
    background-color: #fff;
    position: fixed;
    top: 0;
    right: -450px;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 9999;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    -webkit-transition: ease all 0.8s;
    -ms-transition: ease all 0.8s;
    transition: ease all 0.8s;
  }
  .sidebar-settings.show-settings {
    right: 0;
    -webkit-transition: ease all 0.8s;
    -ms-transition: ease all 0.8s;
    transition: ease all 0.8s;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    width: 100%;
  }
  
  .sidebar-content {
    max-width: 450px;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    height: 100vh;
    overflow-x: hidden;
  }
  .sidebar-content .sidebar-header {
    padding: 20px;
    border: 1px solid #D9D9D9;
  }
  .sidebar-content .sidebar-header h5 {
    font-size: 18px;
    color: #000;
    font-weight: 700;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  .sidebar-content .sidebar-header a {
    font-size: 24px;
  }
  .sidebar-content .sidebar-header a img {
    width: 15px;
  }
  .sidebar-content .sidebar-body {
    padding: 20px;
  }
  .sidebar-content .sidebar-body .theme-title {
    font-size: 18px;
    font-weight: 700;
    color: #6B7A99;
    margin-bottom: 10px;
  }
  .top-sidebar .page-wrapper{
    margin: 0 10px ;
  }